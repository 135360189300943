<template>
      
  <div id="UserRegisterPage_body">

    <loader v-if="showLoader" />
    
      <div>
        <div class="container w-full flex flex-wrap justify-center items-center relative">
          <h1
            class="t-title-2 text-3xl w-auto leading-7 py-3 md:py-0 md:leading-10 mb-0 text-center mx-0 my-0 px-10">
            {{ loggedIn ? "Detalles de tu cuenta" : "Creación de Cuenta" }}
          </h1>
        </div>
      </div>
      <div
        class="flex flex-wrap w-full px-5 pb-20 mb-20 mt-5"
        id="scroll-wraper-content"
      >
        <form
          @submit.prevent="onSubmit"
          class="container mx-auto w-full flex flex-wrap pb-20"
        >
          <div class="flex-1 relative">
            <vue-recaptcha ref="recaptcha" size="invisible" @verify="onVerify" @expired="onExpired" :sitekey="siteKey"></vue-recaptcha>
          </div>

          <div class="w-full container flex flex-wrap z-0 my-5">
            <div
              v-if="!loggedIn"
              class="t-alert success"
            >
              Ahora crearemos una cuenta para ti, así podrás acceder a tus
              pólizas, administrarlas y recuperarlas. Si ya tienes una, puedes
              acceder a ella dando
              <a
                class="
                  text-primary-dark
                  font-sans font-normal
                  border-b
                  transition-all
                  transform
                  duration-300
                  hover:scale-110
                  hover:border-b-2
                  cursor-default
                "
                @click="setIsUserSideBarOpen({ status: true, timeout: 500 })"
              >
                click aquí
              </a>
              .
            </div>
            <div
              v-else
              class="t-alert success"
            >
              Los datos a continuación son tuyos, puedes modificarlos si es necesario, ocuparémos esta información más adelante. Por favor mantén actualizada esta información.
            </div>
            <!-- <h2 class="my-5 text-4xl">
            Datos del asegurado
          </h2> -->
          </div>
          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Datos de cuenta</h2>
                <div class="t-section-content py-5">
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.email && !$v.formData.email.$invalid || !$v.formData.email
                        )
                      "
                      :text="'Email'"
                      :required="true"
                    />
                    <div class="flex-1 max-w-full">
                      <input
                        v-if="!loggedIn"
                        v-model="formData.email"
                        type="email"
                        placeholder="Correo electrónico"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.email.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        v-else
                        class="w-100 text-left t-form cursor-not-allowed"
                      >
                        {{ formData.email }}
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors &&
                          $v.formData.email &&
                          !$v.formData.email.required
                        "
                      >
                        Email requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors &&
                          $v.formData.email &&
                          !$v.formData.email.email
                        "
                      >
                        El email no tiene un formato valido:
                        'tucorreo@email.algo'
                      </div>
                    </div>
                  </div>

                  <div v-if="!loggedIn" class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.email_c && !$v.formData.email_c.$invalid
                        )
                      "
                      :text="'Repetir email'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.email_c"
                        type="email"
                        placeholder="Nuevamente tu email"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.email_c.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.email_c.required"
                      >
                        Es necesaria la confirmación de tu email.
                      </div>
                      <div
                        class="t-form-error"
                        v-else-if="
                          showErrors && !$v.formData.email_c.sameAsEmail
                        "
                      >
                        El email debe ser igual al anterior.
                      </div>
                    </div>
                  </div>
                  <div v-if="!loggedIn" class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.password &&
                            !$v.formData.password.$invalid
                        )
                      "
                      :text="'Contraseña'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.password"
                        type="password"
                        placeholder="Contraseña"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.password.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password.required"
                      >
                        Debes elegir una contraseña de almenos 5
                        <br />carácteres y al menos un símbolo.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password.minLength"
                      >
                        La contraseña debe tener al menos
                        {{
                          $v.formData.password.$params.minLength.min
                        }}
                        carácteres.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password.hasSymbol"
                      >                      
                        La contraseña debe contener al menos un caracter especial ( ! $ % & * + , - . / : ; = ? [ ] ^ _ ` { | } ~ )
                      </div>
                    </div>
                  </div>
                  <div v-if="!loggedIn" class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.password_c &&
                            !$v.formData.password_c.$invalid
                        )
                      "
                      :text="'Repetir Contraseña'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.password_c"
                        type="password"
                        placeholder="Repetir contraseña"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.password_c.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password_c.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors && !$v.formData.password_c.sameAsPassword
                        "
                      >
                        La contraseña debe ser igual a la anterior.
                      </div>
                                            
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Datos personales</h2>
                <div class="t-section-content py-5">
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean($v.formData.name && !$v.formData.name.$invalid)
                      "
                      :text="'Nombre'"
                      :required="true"
                    />
                    <div class="flex-1 max-w-full">
                      <input
                        v-model.trim="$v.formData.name.$model"
                        @input="BillingDataChange()"
                        type="text"
                        placeholder="Nombre"
                        class="t-form"
                        :class="[$v.formData.name.$invalid ? 'invalid' : '']"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.name.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.name.minLength"
                      >
                        El nombre debe tener al menos
                        {{ $v.formData.name.$params.minLength.min }} carácteres
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.name.maxLength"
                      >
                        El nombre debe tener menos de
                        {{ $v.formData.name.$params.maxLength.max }} carácteres
                      </div>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.first_name &&
                            !$v.formData.first_name.$invalid
                        )
                      "
                      :text="'A. Paterno'"
                      :required="true"
                    />
                    <div class="flex-1 max-w-full">
                      <input
                        v-model.trim="$v.formData.first_name.$model"
                        @input="BillingDataChange()"
                        type="text"
                        placeholder="Apellido paterno"
                        class="t-form"
                        :class="[
                          $v.formData.first_name.$invalid ? 'invalid' : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.first_name.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.first_name.minLength"
                      >
                        El apellido debe tener al menos
                        {{ $v.formData.first_name.$params.minLength.min }}
                        carácteres
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.first_name.maxLength"
                      >
                        El apellido debe tener menos de
                        {{ $v.formData.first_name.$params.maxLength.max }}
                        carácteres
                      </div>
                    </div>
                  </div>

                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.last_name &&
                            !$v.formData.last_name.$invalid
                        )
                      "
                      :text="'A. Materno'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.last_name.$model"
                        @input="BillingDataChange()"
                        type="text"
                        placeholder="Apellido materno"
                        class="t-form"
                        :class="[
                          $v.formData.last_name.$invalid ? 'invalid' : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.last_name.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.last_name.minLength"
                      >
                        El apellido debe tener al menos
                        {{ $v.formData.last_name.$params.minLength.min }}
                        carácteres
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.last_name.maxLength"
                      >
                        El apellido debe tener menos de
                        {{ $v.formData.last_name.$params.maxLength.max }}
                        carácteres
                      </div>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.gender && !$v.formData.gender.$invalid
                        )
                      "
                      :text="'Sexo'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model.trim="$v.formData.gender.$model"
                        @input="BillingDataChange()"
                        :options="formDataOptions.gender"
                        index="id"
                        label="name"
                        return-object
                        :class="[
                          showErrors && $v.formData.gender.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                        :disabled="(selectedProductCategories[0].id == 7) ? true : false"
                      >
                      </v-select>

                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.gender.id.required"
                      >
                        Requerido.
                      </div>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.civil_status &&
                            !$v.formData.civil_status.$invalid
                        )
                      "
                      :text="'Estado civil'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model.trim="$v.formData.civil_status.$model"
                        @input="BillingDataChange()"
                        :options="formDataOptions.civil_status"
                        index="id"
                        label="name"
                        return-object
                        :class="[
                          showErrors && $v.formData.civil_status.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      >
                      </v-select>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors && !$v.formData.civil_status.id.required
                        "
                      >
                        Requerido.
                      </div>
                    </div>
                  </div>

                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean($v.formData.rfc && !$v.formData.rfc.$invalid)
                      "
                      :text="'RFC'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.rfc.$model"
                        @input="
                          $v.formData.rfc.$model = String(
                            $v.formData.rfc.$model
                          ).toUpperCase()
                          BillingDataChange()
                        "
                        type="text"
                        maxlength="10"
                        placeholder="10 carácteres alfanumericos"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.rfc.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.rfc.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.rfc.rfc"
                      >
                        No es un RFC válido.
                      </div>
                      
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.rfc.isRFCValid"
                      >
                        El RFC no corresponde con tu nombre y fecha de nacimiento
                      </div>



                    </div>
                  </div>





                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.homoclave &&
                            !$v.formData.homoclave.$invalid
                        )
                      "
                      :text="'Homoclave'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.homoclave.$model"                        
                        @input="
                          $v.formData.homoclave.$model = String(
                            $v.formData.homoclave.$model
                          ).toUpperCase()

                          BillingDataChange()
                        "
                        type="text"
                        maxlength="3"
                        placeholder="3 carácteres alfanumericos"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.homoclave.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.homoclave.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors &&
                          (!$v.formData.homoclave.maxLength ||
                            !$v.formData.homoclave.minLength)
                        "
                      >
                        La homoclave tiene 3 carácteres.
                      </div>
                    </div>
                  </div>
                  





                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.born_date &&
                            !$v.formData.born_date.$invalid
                        )
                      "
                      :text="'Fecha de nacimiento'"
                      :required="true"
                    />

                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.born_date.$model"
                        @input="BillingDataChange()"
                        type="date"
                        maxlength="3"
                        placeholder="3 carácteres alfanumericos"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.born_date.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                        :disabled="(selectedProductCategories[0].id == 7) ? true : false"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.born_date.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.born_date.maxValue"
                      >
                        La fecha no puede ser futura
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.born_date.isMinValidAge"
                      >
                        Debes ser mayor de 18 años para comprar un seguro.
                      </div>
                    </div>
                  </div>



                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.phone_number && !$v.formData.phone_number.$invalid || !$v.formData.phone_number
                        )
                      "
                      :text="'Teléfono'"
                      :required="true"
                    />

                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.phone_number.$model"
                        @input="BillingDataChange()"
                        type="number"
                        placeholder="Teléfono"
                        class="t-form"
                        maxlength="10"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"    
                        :class="[
                        $v.formData.phone_number.$invalid && showErrors
                          ? 'invalid'
                          : ''
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="!$v.formData.phone_number.required && showErrors"
                      >
                        Número teléfonico requerido
                      </div>
                    </div>
                  </div>







                </div>
              </div>
            </div>
          </div>
          
          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Dirección</h2>
                <div class="t-section-content py-5">



                <div v-if="selectedProductCategories[0].id != 7">

                  <div class="t-input-group">
                    <label for="name" class="t-label">C.P.</label>

                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.zip_code"                        
                        :options="formDataOptions.zip_codes"
                        @input="
                          refreshAddressInfo()
                          BillingDataChange()
                        "
                        index="id"
                        label="name"
                        @search="onSearchCP"
                        return-object
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">País</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.country"
                        @input="BillingDataChange()"
                        
                        :options="[{ id: 1, name: 'México' }]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Estado</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.state"
                        @input="BillingDataChange()"
                        :options="[formData.state]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Municipio</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.town_council"
                        @input="BillingDataChange()"
                        :options="[formData.town_council]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Colonia</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.colony"
                        @input="BillingDataChange()"
                        :options="[formData.colony]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>

                </div>





                <div class="w-full flex flex-wrap">
                  <div
                    class="
                      w-6/12
                      text-right
                      flex flex-wrap
                      justify-end
                      items-start
                      align-end
                      relative
                      mb-8
                      sm:mb-3
                    "
                  >
                    <label for="name" class="t-label">Calle</label>
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.street"
                        @input="BillingDataChange()"
                        type="text"
                        class="t-form"
                        :class="[
                          $v.formData.street.$invalid ? 'invalid' : '',
                        ]"
                      />
                    </div>
                  </div>
                  <div
                    class="
                      w-3/12
                      text-right
                      flex flex-wrap
                      justify-end
                      items-start
                      align-end
                      relative
                      mb-8
                      sm:mb-3
                    "
                  >
                    <label for="name" class="t-label">#Ext</label>
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.num_ext"
                        @input="BillingDataChange()"
                        type="text"
                        class="t-form"
                        :class="[
                          $v.formData.num_ext.$invalid ? 'invalid' : '',
                        ]"
                      />
                    </div>
                  </div>
                  <div
                    class="
                      w-3/12
                      text-right
                      flex flex-wrap
                      justify-end
                      items-start
                      align-end
                      relative
                      mb-8
                      sm:mb-3
                    "
                  >
                    <label for="name" class="t-label">#Int</label>
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.num_int"
                        @input="BillingDataChange()"
                        type="text"
                        class="t-form"
                        :class="[
                          $v.formData.num_int.$invalid ? 'invalid' : '',
                        ]"
                      />
                    </div>
                  </div>
                  <div class="w-full">
                    <div
                      class="t-form-error"
                      v-if="showErrors && !$v.formData.num_ext.required"
                    >
                      Número exterior requerido
                    </div>
                    <div
                      class="t-form-error"
                      v-if="showErrors && !$v.formData.street.maxLength"
                    >
                      Máximo
                      {{ $v.formData.street.$params.maxLength.max }}
                      carácteres
                    </div>
                  </div>
                </div>


                





                </div>
              </div>
            </div>
          </div>






          <div class="w-full" v-if="selectedProductCategories[0].id != 7">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Facturación</h2>
                <div class="t-section-content py-5">


                  <div class="t-input-group">
                    <label for="name" class="t-label">¿Requieres Fáctura?</label>
                    <div class="flex-1 relative">
                      
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model.trim="$v.formData.require_billing.$model"
                        @input="BillingDataChange()"

                        :options="close_questions"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>

                    </div>
                  </div>







                  

                



                  <!--? Billing Data -->

                  <!--TODO Condicional 1 ¿Requieres Factura? -->

                  <div v-if="formData.require_billing.value"
                    class="container flex flex-1 justify-center flex-wrap"
                  >

                    <!--TODO Condicional 2 ¿La factura se emitirá a tu nombre? -->

                    <div class="t-input-group">
                      <t-label
                        class="t-label"
                        :showStatus="showErrors"
                        :valid="Boolean($v.formData.personal_billing_expedition.$model)"
                        :text="'¿La factura se emitirá a tu Nombre?'"
                        :required="true"
                      />
                      <div class="flex-1 max-w-full">

                        <v-select
                          v-model.trim="$v.formData.personal_billing_expedition.$model"
                          @input="FullFillBillingChange()"
                          :options="close_questions"
                          index="id"
                          label="name"
                          class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                        >
                        
                        </v-select>

                        <div
                          class="t-form-error"
                          v-if="!$v.formData.personal_billing_expedition.$model && showErrors"
                        >
                          Es necesario saber si la fáctura será emitida a tu nombre o a nombre de alguien más
                        </div>
                      </div>
                    </div>
                    

                    <!--TODO Condicional 3 ¿Ya Subío su Contancia de Situación Fiscal con Formato Válido (pdf)? -->
                    <!--TODO Condicional 4 ¿Ya Subío su Identificación Oficial con Formato Válido (png,pdf,jpg)? -->


                    <!-- TODO Condicional 5-V Unicamente si requiere factura externa se cumple este caso -->
                    <div v-if="
                      formData.require_billing.value && 
                      !$v.formData.personal_billing_expedition.$model.value"
                    class="w-full">

                      <div class="w-full">
                        <div class="w-full">
                          <br><br><br>
                          <div class="t-form-section">
                            <h2 class="t-section-title py-0" style="margin-right:20px;">Datos personales para Facturación</h2>
                            <div class="t-section-content py-5">
                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean($v.formData.billing_external_name && !$v.formData.billing_external_name.$invalid)
                                  "
                                  :text="'Nombre'"
                                  :required="true"
                                />
                                <div class="flex-1 max-w-full">
                                  <input
                                    v-model.trim="$v.formData.billing_external_name.$model"
                                    @input="BillingDataChange()"
                                    type="text"
                                    placeholder="Nombre"
                                    class="t-form"
                                    :class="[$v.formData.billing_external_name.$invalid ? 'invalid' : '']"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_name.required"
                                  >
                                    Requerido.
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_name.minLength"
                                  >
                                    El nombre debe tener al menos
                                    {{ $v.formData.billing_external_name.$params.minLength.min }} carácteres
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_name.maxLength"
                                  >
                                    El nombre debe tener menos de
                                    {{ $v.formData.billing_external_name.$params.maxLength.max }} carácteres
                                  </div>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_first_name &&
                                        !$v.formData.billing_external_first_name.$invalid
                                    )
                                  "
                                  :text="'A. Paterno'"
                                  :required="true"
                                />
                                <div class="flex-1 max-w-full">
                                  <input
                                    v-model.trim="$v.formData.billing_external_first_name.$model"
                                    @input="BillingDataChange()"
                                    type="text"
                                    placeholder="Apellido paterno"
                                    class="t-form"
                                    :class="[
                                      $v.formData.billing_external_first_name.$invalid ? 'invalid' : '',
                                    ]"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_first_name.required"
                                  >
                                    Requerido.
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_first_name.minLength"
                                  >
                                    El apellido debe tener al menos
                                    {{ $v.formData.billing_external_first_name.$params.minLength.min }}
                                    carácteres
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_first_name.maxLength"
                                  >
                                    El apellido debe tener menos de
                                    {{ $v.formData.billing_external_first_name.$params.maxLength.max }}
                                    carácteres
                                  </div>
                                </div>
                              </div>

                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_last_name &&
                                        !$v.formData.billing_external_last_name.$invalid
                                    )
                                  "
                                  :text="'A. Materno'"
                                  :required="true"
                                />
                                <div class="flex-1 relative">
                                  <input
                                    v-model.trim="$v.formData.billing_external_last_name.$model"
                                    @input="BillingDataChange()"
                                    type="text"
                                    placeholder="Apellido materno"
                                    class="t-form"
                                    :class="[
                                      $v.formData.billing_external_last_name.$invalid ? 'invalid' : '',
                                    ]"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_last_name.required"
                                  >
                                    Requerido.
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_last_name.minLength"
                                  >
                                    El apellido debe tener al menos
                                    {{ $v.formData.billing_external_last_name.$params.minLength.min }}
                                    carácteres
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_last_name.maxLength"
                                  >
                                    El apellido debe tener menos de
                                    {{ $v.formData.billing_external_last_name.$params.maxLength.max }}
                                    carácteres
                                  </div>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_gender && !$v.formData.billing_external_gender.$invalid
                                    )
                                  "
                                  :text="'Sexo'"
                                  :required="true"
                                />
                                <div class="flex-1 relative">
                                  <v-select
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model.trim="$v.formData.billing_external_gender.$model"
                                    @input="BillingDataChange()"
                                    :options="formDataOptions.gender"
                                    index="id"
                                    label="name"
                                    return-object
                                    :class="[
                                      showErrors && $v.formData.billing_external_gender.$invalid
                                        ? 'invalid'
                                        : '',
                                    ]"
                                  >
                                  </v-select>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_gender.id.required"
                                  >
                                    Requerido.
                                  </div>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_civil_status &&
                                        !$v.formData.billing_external_civil_status.$invalid
                                    )
                                  "
                                  :text="'Estado civil'"
                                  :required="true"
                                />
                                <div class="flex-1 relative">
                                  <v-select
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model.trim="$v.formData.billing_external_civil_status.$model"
                                    @input="BillingDataChange()"
                                    :options="formDataOptions.civil_status"
                                    index="id"
                                    label="name"
                                    return-object
                                    :class="[
                                      showErrors && $v.formData.billing_external_civil_status.$invalid
                                        ? 'invalid'
                                        : '',
                                    ]"
                                  >
                                  </v-select>
                                  <div
                                    class="t-form-error"
                                    v-if="
                                      showErrors && !$v.formData.billing_external_civil_status.id.required
                                    "
                                  >
                                    Requerido.
                                  </div>
                                </div>
                              </div>

                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean($v.formData.billing_external_rfc && !$v.formData.billing_external_rfc.$invalid)
                                  "
                                  :text="'RFC'"
                                  :required="true"
                                />
                                <div class="flex-1 relative">
                                  <input
                                    v-model.trim="$v.formData.billing_external_rfc.$model"
                                    @input="
                                      $v.formData.billing_external_rfc.$model = String(
                                        $v.formData.billing_external_rfc.$model
                                      ).toUpperCase()
                                      BillingDataChange()
                                    "
                                    type="text"
                                    maxlength="10"
                                    placeholder="10 carácteres alfanumericos"
                                    class="t-form"
                                    :class="[
                                      showErrors && $v.formData.billing_external_rfc.$invalid
                                        ? 'invalid'
                                        : '',
                                    ]"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_rfc.required"
                                  >
                                    Requerido.
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_rfc.rfc"
                                  >
                                    No es un RFC válido.
                                  </div>
                                </div>
                              </div>


                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_homoclave &&
                                        !$v.formData.billing_external_homoclave.$invalid
                                    )
                                  "
                                  :text="'Homoclave'"
                                  :required="true"
                                />
                                <div class="flex-1 relative">
                                  <input
                                    v-model.trim="$v.formData.billing_external_homoclave.$model"                        
                                    @input="
                                      $v.formData.billing_external_homoclave.$model = String(
                                        $v.formData.billing_external_homoclave.$model
                                      ).toUpperCase()

                                      BillingDataChange()
                                    "
                                    type="text"
                                    maxlength="3"
                                    placeholder="3 carácteres alfanumericos"
                                    class="t-form"
                                    :class="[
                                      showErrors && $v.formData.billing_external_homoclave.$invalid
                                        ? 'invalid'
                                        : '',
                                    ]"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_homoclave.required"
                                  >
                                    Requerido.
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="
                                      showErrors &&
                                      (!$v.formData.billing_external_homoclave.maxLength ||
                                        !$v.formData.billing_external_homoclave.minLength)
                                    "
                                  >
                                    La homoclave tiene 3 carácteres.
                                  </div>
                                </div>
                              </div>
                              

                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_born_date &&
                                        !$v.formData.billing_external_born_date.$invalid
                                    )
                                  "
                                  :text="'Fecha de nacimiento'"
                                  :required="true"
                                />

                                <div class="flex-1 relative">
                                  <input
                                    v-model.trim="$v.formData.billing_external_born_date.$model"
                                    @input="BillingDataChange()"
                                    type="date"
                                    maxlength="3"
                                    placeholder="3 carácteres alfanumericos"
                                    class="t-form"
                                    :class="[
                                      showErrors && $v.formData.billing_external_born_date.$invalid
                                        ? 'invalid'
                                        : '',
                                    ]"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_born_date.required"
                                  >
                                    Requerido.
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_born_date.maxValue"
                                  >
                                    La fecha no puede ser futura
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_born_date.isMinValidAge"
                                  >
                                    Debes ser mayor de 18 años para comprar un seguro.
                                  </div>
                                </div>
                              </div>


                              <div class="t-input-group">
                                <t-label
                                  class="t-label"
                                  :showStatus="showErrors"
                                  :valid="
                                    Boolean(
                                      $v.formData.billing_external_phone_number && !$v.formData.billing_external_phone_number.$invalid || !$v.formData.billing_external_phone_number
                                    )
                                  "
                                  :text="'Teléfono'"
                                  :required="true"
                                />

                                <div class="flex-1 relative">
                                  <input
                                    v-model.trim="$v.formData.billing_external_phone_number.$model"
                                    @input="BillingDataChange()"
                                    type="number"
                                    placeholder="Teléfono"
                                    class="t-form"
                                    maxlength="10"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"    
                                    :class="[
                                    $v.formData.billing_external_phone_number.$invalid && showErrors
                                      ? 'invalid'
                                      : ''
                                    ]"
                                  />
                                  <div
                                    class="t-form-error"
                                    v-if="!$v.formData.billing_external_phone_number.required && showErrors"
                                  >
                                    Número teléfonico requerido
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="w-full">
                        <div class="w-full">
                          <div class="t-form-section">
                            <h2 class="t-section-title py-0" style="margin-right:20px;">Dirección</h2>
                            <div class="t-section-content py-5">
                              <div class="t-input-group">
                                <label for="name" class="t-label">C.P.</label>

                                <div class="flex-1 relative">
                                  <v-select
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model="formData.billing_external_zip_code"                        
                                    :options="formDataOptions.zip_codes"
                                    @input="
                                      BillingExternalRefreshAddressInfo()
                                      BillingDataChange()
                                    "
                                    index="id"
                                    label="name"
                                    @search="onSearchCP"
                                    return-object
                                  >
                                    <template slot="option" slot-scope="option">
                                      <div class="d-center">
                                        {{ option.name }}
                                      </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                      <div class="selected d-center">
                                        {{ option.name }}
                                      </div>
                                    </template>
                                  </v-select>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <label for="name" class="t-label">País</label>
                                <div class="flex-1 relative">
                                  <v-select
                                    disabled
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model="formData.billing_external_country"
                                    @input="BillingDataChange()"
                                    
                                    :options="[{ id: 1, name: 'México' }]"
                                    index="id"
                                    label="name"
                                    return-object
                                  >
                                  </v-select>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <label for="name" class="t-label">Estado</label>
                                <div class="flex-1 relative">
                                  <v-select
                                    disabled
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model="formData.billing_external_state"
                                    @input="BillingDataChange()"
                                    :options="[formData.state]"
                                    index="id"
                                    label="name"
                                    return-object
                                  >
                                  </v-select>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <label for="name" class="t-label">Municipio</label>
                                <div class="flex-1 relative">
                                  <v-select
                                    disabled
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model="formData.billing_external_town_council"
                                    @input="BillingDataChange()"
                                    :options="[formData.town_council]"
                                    index="id"
                                    label="name"
                                    return-object
                                  >
                                  </v-select>
                                </div>
                              </div>
                              <div class="t-input-group">
                                <label for="name" class="t-label">Colonia</label>
                                <div class="flex-1 relative">
                                  <v-select
                                    disabled
                                    type="text"
                                    class="t-form h-max-10"
                                    v-model="formData.billing_external_colony"
                                    @input="BillingDataChange()"
                                    :options="[formData.colony]"
                                    index="id"
                                    label="name"
                                    return-object
                                  >
                                  </v-select>
                                </div>
                              </div>

                              <div class="w-full flex flex-wrap">
                                <div
                                  class="
                                    w-6/12
                                    text-right
                                    flex flex-wrap
                                    justify-end
                                    items-start
                                    align-end
                                    relative
                                    mb-8
                                    sm:mb-3
                                  "
                                >
                                  <label for="name" class="t-label">Calle</label>
                                  <div class="flex-1 relative">
                                    <input
                                      v-model="formData.billing_external_street"
                                      @input="BillingDataChange()"
                                      type="text"
                                      class="t-form"
                                      :class="[
                                        $v.formData.billing_external_street.$invalid ? 'invalid' : '',
                                      ]"
                                    />
                                  </div>
                                </div>
                                <div
                                  class="
                                    w-3/12
                                    text-right
                                    flex flex-wrap
                                    justify-end
                                    items-start
                                    align-end
                                    relative
                                    mb-8
                                    sm:mb-3
                                  "
                                >
                                  <label for="name" class="t-label">#Ext</label>
                                  <div class="flex-1 relative">
                                    <input
                                      v-model="formData.billing_external_num_ext"
                                      @input="BillingDataChange()"
                                      type="text"
                                      class="t-form"
                                      :class="[
                                        $v.formData.billing_external_num_ext.$invalid ? 'invalid' : '',
                                      ]"
                                    />
                                  </div>
                                </div>
                                <div
                                  class="
                                    w-3/12
                                    text-right
                                    flex flex-wrap
                                    justify-end
                                    items-start
                                    align-end
                                    relative
                                    mb-8
                                    sm:mb-3
                                  "
                                >
                                  <label for="name" class="t-label">#Int</label>
                                  <div class="flex-1 relative">
                                    <input
                                      v-model="formData.billing_external_num_int"
                                      @input="BillingDataChange()"
                                      type="text"
                                      class="t-form"
                                      :class="[
                                        $v.formData.billing_external_num_int.$invalid ? 'invalid' : '',
                                      ]"
                                    />
                                  </div>
                                </div>
                                <div class="w-full">
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_num_ext.required"
                                  >
                                    Número exterior requerido
                                  </div>
                                  <div
                                    class="t-form-error"
                                    v-if="showErrors && !$v.formData.billing_external_street.maxLength"
                                  >
                                    Máximo
                                    {{ $v.formData.billing_external_street.$params.maxLength.max }}
                                    carácteres
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>




                    <!--TODO Condicional 6 ¿Que seguro requieres para partiendo de ahí mostrar las opciones con sus respectivos catalogos? -->
                    <div v-if="
                        formData.require_billing.value && 
                        $v.formData.personal_billing_expedition.$model
                      " class="w-full my-5 text-2xl text-center">

                      <!--? Estructura Dental Dentegra -->
                      <div v-if="selectedProductCategories[0].id == 5" class="w-full my-5 text-2xl text-center">

                        <div class="w-full my-5 text-2xl text-center text-primary">
                          <h1 style="text-transform:uppercase;">Datos de Facturación</h1>
                          <h1 style="font-size:15px;" class="">Enviar los siguientes datos al correo: notificaciones@thbmexico.com</h1>
                          <ul style="color:black; font-size: 15px; text-align:left;">
                              <li>» Nombre Completo</li>
                              <li>» Identificación Oficial Vigente Escaneada por ambos lados.</li>
                              <li>» Rfc</li>
                              <li>» Homoclave</li>
                          </ul>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="Boolean($v.formData.occupation.$model)"
                            :text="'¿Cuál es tu Ocupación?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              v-model.trim="$v.formData.occupation.$model"
                              @input="BillingDataChange()"
                              :options="occupations"
                              index="id"
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            
                            </v-select>
                            
                            <div
                              class="t-form-error"
                              v-if="!$v.formData.occupation.$model && showErrors"
                            >
                              La ocupación es obligatoria.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="Boolean($v.formData.nationality.$model)"
                            :text="'¿Cuál es tu Nacionalidad?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">
                            <v-select
                              v-model.trim="$v.formData.nationality.$model"
                              @input="BillingDataChange()"
                              :options="nationalities"
                              index="id"
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>
                            
                            <div
                              class="t-form-error"
                              v-if="!$v.formData.nationality.$model && showErrors"
                            >
                              La nacionalidad es obligatoria.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.birth_country.$model)
                            "
                            :text="'¿Cuál es tu país de Nacimiento?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.birth_country.$model"
                              @input="BillingDataChange()"
                              :options="countries"

                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >

                            </v-select>

                            <div
                              class="t-form-error"
                              v-if="!$v.formData.birth_country.$model && showErrors"
                            >
                              El país de nacimiento es obligatorio.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.fiscal_regime.$model)
                            "
                            :text="'¿Cuál es tu Régimen Fiscal?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.fiscal_regime.$model"
                              @input="BillingDataChange()"
                              :options="list_regime_fiscal"

                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>

                            <div
                              class="t-form-error"
                              v-if="!$v.formData.fiscal_regime.$model && showErrors"
                            >
                              El Regimen Fiscal es obligatorio.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.cfdi_use.$model)
                            "
                            :text="'¿Cuál es tu Uso de CFDI?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.cfdi_use.$model"
                              :options="list_cfdi_use"
                              @input="BillingDataChange()"
                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>

                            <div
                              class="t-form-error"
                              v-if="!$v.formData.cfdi_use.$model && showErrors"
                            >
                              El uso de CFDI es obligatorio.
                            </div>
                          </div>
                        </div>
                        

                        <!--? CONSTANCIA DE SITUACION FISCAL UPLOAD -->
                        <div class="t-input-group">
                          <t-label v-if="formData.fiscal_situation_constancy"
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.fiscal_situation_constancy.$model)
                            "
                            :text="'Actualiza tu Constancia de Situación Fiscal si así lo requirieses, ya contamos con una tuya!'"
                            :required="true"
                          />

                          <t-label v-else
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.fiscal_situation_constancy.$model)
                            "
                            :text="'Sube tu Constancia de Situación Fiscal (Vigente)'"
                            :required="true"
                          />
                      
                          <div class="DragAndDropFileView flex-1 max-w-full">

                              <div class="DragAndDropFileView_container">
                                  <div v-if="!fiscal_situation_constancy_file" id="dropZone_waiting">
                                      <div :class="['dropZone', fiscal_situation_constancy_dragging ? 'dropZone-over' : '']" @dragenter="fiscal_situation_constancy_dragging = true" @dragleave="fiscal_situation_constancy_dragging = false">
                                          <div class="dropZone-info" @drag="onChangeFileFiscalSituationConstancy">
                                              <span class="fa fa-cloud-upload dropZone-title"></span>
                                              <span class="dropZone-title">Arrastra el archivo en el área punteada o da click para subirlo</span>
                                              <div class="dropZone-upload-limit-info">
                                                  <div>Extensiones Soportadas: {{ fiscal_situation_constancy_validExtensions }}</div>
                                                  <div>Tamaño máximo del Archivo: {{ fiscal_situation_constancy_maximum_file_size / 1000 }} KB</div>
                                              </div>
                                              <span class="dropZone-error text-danger" v-if="showErrors"> {{ fiscal_situation_constancy_fileError }} </span>
                                          </div>
                                          <input type="file" @change="onChangeFileFiscalSituationConstancy">
                                      </div>
                                  </div>

                                  <div v-else id="dropZone_uploaded">
                                      <div class="dropZone_uploaded_info">
                                          <span class="dropZone-title">Archivo Cargado ({{ fiscal_situation_constancy_file.name }})</span>
                                          <div id="btns_interactive">
                                              <button type="button" class="btn btn-danger" @click="removeFileFiscalSituationConstancy">Remover Archivo</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>

                        </div>

                      </div>

                      <!--? Estructura Thona -->
                      <div v-else class="w-full my-5 text-2xl text-center">

                        <div class="w-full my-5 text-2xl text-center text-primary">
                          <h1 style="text-transform:uppercase;">Datos de Facturación</h1>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="Boolean($v.formData.occupation.$model)"
                            :text="'¿Cuál es su Ocupación?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              v-model.trim="$v.formData.occupation.$model"
                              @input="BillingDataChange()"
                              :options="occupations"
                              index="id"
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            
                            </v-select>
                            
                            <div
                              class="t-form-error"
                              v-if="!$v.formData.occupation.$model && showErrors"
                            >
                              La ocupación es obligatoria.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="Boolean($v.formData.nationality.$model)"
                            :text="'¿Cuál es su Nacionalidad?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">
                            <v-select
                              v-model.trim="$v.formData.nationality.$model"
                              @input="BillingDataChange()"
                              :options="nationalities"
                              index="id"
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>
                            
                            <div
                              class="t-form-error"
                              v-if="!$v.formData.nationality.$model && showErrors"
                            >
                              La nacionalidad es obligatoria.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.birth_country.$model)
                            "
                            :text="'¿Cuál es su país de Nacimiento?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.birth_country.$model"
                              @input="BillingDataChange()"
                              :options="countries"

                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >

                            </v-select>

                            <div
                              class="t-form-error"
                              v-if="!$v.formData.birth_country.$model && showErrors"
                            >
                              El país de nacimiento es obligatorio.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.fiscal_regime.$model)
                            "
                            :text="'¿Cuál es su Régimen Fiscal?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.fiscal_regime.$model"
                              @input="BillingDataChange()"
                              :options="list_regime_fiscal"

                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>

                            <div
                              class="t-form-error"
                              v-if="!$v.formData.fiscal_regime.$model && showErrors"
                            >
                              El Regimen Fiscal es obligatorio.
                            </div>
                          </div>
                        </div>


                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.cfdi_use.$model)
                            "
                            :text="'¿Cuál es el Uso de Cfdi?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.cfdi_use.$model"
                              :options="list_cfdi_use"
                              @input="BillingDataChange()"
                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>

                            <div
                              class="t-form-error"
                              v-if="!$v.formData.cfdi_use.$model && showErrors"
                            >
                              El uso de CFDI es obligatorio.
                            </div>
                          </div>
                        </div>

                        <div class="t-input-group">
                          <t-label
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.taxes_object.$model)
                            "
                            :text="'¿Cuál es el Objeto de Impuesto?'"
                            :required="true"
                          />
                          <div class="flex-1 max-w-full">

                            <v-select
                              ref="focusForm"
                              v-model.trim="$v.formData.taxes_object.$model"
                              @input="BillingDataChange()"
                              :options="list_taxes_object"
                              index="id"
                              return-object
                              label="name"
                              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                            >
                            </v-select>

                            <!-- PageData:
                            {{ pageData }}

                            FormData Taxes Object:
                            {{ formData.taxes_object }}

                            External:
                            {{ formData.external_data_fiscal }} -->


                            <div
                              class="t-form-error"
                              v-if="!$v.formData.taxes_object.$model && showErrors"
                            >
                              El uso de Impuesto es obligatorio.
                            </div>
                          </div>
                        </div>









                        <!--? CONSTANCIA DE SITUACION FISCAL UPLOAD -->
                        <div class="t-input-group">
                          <t-label v-if="formData.fiscal_situation_constancy"
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.fiscal_situation_constancy.$model)
                            "
                            :text="'Actualiza tu Constancia de Situación Fiscal si así lo requirieses, ya contamos con una tuya!'"
                            :required="true"
                          />

                          <t-label v-else
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.fiscal_situation_constancy.$model)
                            "
                            :text="'Sube tu Constancia de Situación Fiscal (Vigente)'"
                            :required="true"
                          />
                      
                          <div class="DragAndDropFileView flex-1 max-w-full">

                              <div class="DragAndDropFileView_container">
                                  <div v-if="!fiscal_situation_constancy_file" id="dropZone_waiting">
                                      <div :class="['dropZone', fiscal_situation_constancy_dragging ? 'dropZone-over' : '']" @dragenter="fiscal_situation_constancy_dragging = true" @dragleave="fiscal_situation_constancy_dragging = false">
                                          <div class="dropZone-info" @drag="onChangeFileFiscalSituationConstancy">
                                              <span class="fa fa-cloud-upload dropZone-title"></span>
                                              <span class="dropZone-title">Arrastra el archivo en el área punteada o da click para subirlo</span>
                                              <div class="dropZone-upload-limit-info">
                                                  <div>Extensiones Soportadas: {{ fiscal_situation_constancy_validExtensions }}</div>
                                                  <div>Tamaño máximo del Archivo: {{ fiscal_situation_constancy_maximum_file_size / 1000 }} KB</div>
                                              </div>
                                              <span class="dropZone-error text-danger" v-if="showErrors"> {{ fiscal_situation_constancy_fileError }} </span>
                                          </div>
                                          <input type="file" @change="onChangeFileFiscalSituationConstancy">
                                      </div>
                                  </div>

                                  <div v-else id="dropZone_uploaded">
                                      <div class="dropZone_uploaded_info">
                                          <span class="dropZone-title">Archivo Cargado ({{ fiscal_situation_constancy_file.name }})</span>
                                          <div id="btns_interactive">
                                              <button type="button" class="btn btn-danger" @click="removeFileFiscalSituationConstancy">Remover Archivo</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>

                        </div>


                        <div class="t-input-group">
                          <button style="font-size:10px; color:gray;" @click="help_fiscal_situation_constancy=true">
                            ¿Como puedo obtener mi Constancia de Situación Fiscal?
                          </button>
                        </div>
                        <div v-if="help_fiscal_situation_constancy" class="w-full my-5 text-2xl text-center text-primary" style="font-size:15px; text-align:left;">
                          <h1 style="text-transform:uppercase;">Información para obtener la Constancia de Situación Fiscal</h1>
                          <p>1. Ingresar al sitio: https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal</p>                            
                          <p>2. Elige el medio de autenticación (contraseña o e.firma)</p>
                          <p>3. Registra los datos de autenticación</p>
                          <p>4. Selecciona el botón Generar Constancia</p>
                          <p>5. Imprime o guarda en tu dispositivo electrónico</p>
                        </div>
                        

                        


                        <!--? IDENTIFICACIÓN OFICIAL UPLOAD -->
                        <div class="t-input-group">

                          <t-label v-if="!formData.identity_document_front && formData.identity_document_back"
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.identity_document_front.$model && $v.formData.identity_document_back.$model)
                            "
                            :text="'Actualiza tu Identificación Oficial INE vigente si así lo requirieses, actualmente ya contamos con una tuya! (Anverso y Reverso)'"
                            :required="true"
                          />

                          <t-label v-else
                            class="t-label"
                            :showStatus="showErrors"
                            :valid="
                              Boolean($v.formData.identity_document_front.$model && $v.formData.identity_document_back.$model)
                            "
                            :text="'Sube tu Identificación Oficial INE vigente (Anverso y Reverso)'"
                            :required="true"
                          />


                          <!--? Frontal -->
                      
                          <div class="DragAndDropFileView flex-1 max-w-full">

                              <div class="DragAndDropFileView_container">
                                  <div v-if="!identity_document_front_file" id="dropZone_waiting">
                                      <div :class="['dropZone', identity_document_front_dragging ? 'dropZone-over' : '']" @dragenter="identity_document_front_dragging = true" @dragleave="identity_document_front_dragging = false">
                                          <div class="dropZone-info" @drag="onChangeFileIdentityDocumentFront">
                                              <h3 style="color:black; font-weight:bold;">Anverso</h3>
                                              <span class="fa fa-cloud-upload dropZone-title"></span>
                                              <span class="dropZone-title">Arrastra el archivo en el área punteada o da click para subirlo</span>
                                              <div class="dropZone-upload-limit-info">
                                                  <div>Extensiones Soportadas: {{ identity_document_front_validExtensions }}</div>
                                                  <div>Tamaño máximo del Archivo: {{ identity_document_front_maximum_file_size / 1000 }} KB</div>
                                              </div>
                                              <span class="dropZone-error text-danger" v-if="showErrors"> {{ identity_document_front_fileError }} </span>
                                          </div>
                                          <input type="file" @change="onChangeFileIdentityDocumentFront">
                                      </div>
                                  </div>

                                  <div v-else id="dropZone_uploaded">
                                      <div class="dropZone_uploaded_info">
                                          <span class="dropZone-title">Archivo Cargado ({{ identity_document_front_file.name }})</span>
                                          <div id="btns_interactive">
                                              <button type="button" class="btn btn-danger" @click="removeFileIdentityDocumentFront">Remover Archivo</button>
                                          </div>

                                      </div>
                                  </div>
                              </div>

                          </div>



                          <!--? Trasera -->

                          <div class="DragAndDropFileView flex-1 max-w-full">

                              <div class="DragAndDropFileView_container">
                                  <div v-if="!identity_document_back_file" id="dropZone_waiting">
                                      <div :class="['dropZone', identity_document_back_dragging ? 'dropZone-over' : '']" @dragenter="identity_document_back_dragging = true" @dragleave="identity_document_back_dragging = false">
                                          <div class="dropZone-info" @drag="onChangeFileIdentityDocumentBack">
                                              <h3 style="color:black; font-weight:bold;">Reverso</h3>
                                              <span class="fa fa-cloud-upload dropZone-title"></span>
                                              <span class="dropZone-title">Arrastra el archivo en el área punteada o da click para subirlo</span>
                                              <div class="dropZone-upload-limit-info">
                                                  <div>Extensiones Soportadas: {{ identity_document_back_validExtensions }}</div>
                                                  <div>Tamaño máximo del Archivo: {{ identity_document_back_maximum_file_size / 1000 }} KB</div>
                                              </div>
                                              <span class="dropZone-error text-danger" v-if="showErrors"> {{ identity_document_back_fileError }} </span>
                                          </div>
                                          <input type="file" @change="onChangeFileIdentityDocumentBack">
                                      </div>
                                  </div>

                                  <div v-else id="dropZone_uploaded">
                                      <div class="dropZone_uploaded_info">
                                          <span class="dropZone-title">Archivo Cargado ({{ identity_document_back_file.name }})</span>
                                          <div id="btns_interactive">
                                              <button type="button" class="btn btn-danger" @click="removeFileIdentityDocumentBack">Remover Archivo</button>
                                          </div>

                                      </div>
                                  </div>
                              </div>

                          </div>          
                  
                        </div>


                      </div>

                    </div>

                  </div>




                  <!--TODO Condicional 1.2 Mensaje de aviso No requiere factura-->
                  <div v-else>
                    <div class="w-full my-5 text-2xl text-center text-primary">
                      <h1 style="text-transform:uppercase;">Información de Facturación</h1>
                      <h1 style="font-size:15px;" class="">Estimado cliente, te recordamos que en caso de que no desees factura, 
                        tu póliza se facturará a público en general y no podrá ser solicitada con posterioridad</h1>
                    </div>
                  </div>


                </div>



              </div>
            </div>
          </div>

          <!-- Despliega errores provenientes de las APIs -->
          <div v-if="showAPIErrors" class="w-full flex flex-wrap justify-end">
            <div class="t-form-error block">
              <ul v-for="(error, index) in ApiErrorMessages" :key="index">
                <li>{{ error.title }}</li>
              </ul>
            </div>
          </div>

          <div class="w-full flex flex-wrap justify-end">
            <div
              v-if="showErrors && $v.formData.$invalid"
              
              class="
                w-full
                rounded-md
                px-5
                py-2
                border-2 border-red-700
                bg-red-50
                text-red-700
                my-3
              "
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
                class="fa-lg"
              />
              Algunos campos requieren de tu atención antes de poder continuar.
            </div>
            <button
              type="submit"
              class="
                bg-special-3
                h-10
                md:px-10
                rounded-md
                text-primary text-xl md:text-2xl
                w-full
                sm:w-auto
              "
            >
              Continuar con requisitos
            </button>
          </div>
        </form>
      </div>
      <div>
        <div class="container mx-auto z-0" style="box-shadow: rgb(255 255 255) 0px 0px 150px 75px;">
          
        </div>
      </div>

  </div>
  
</template>




<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
import { siKey } from "../constants/config"
import { 
  required,
  requiredIf,
  minLength,
  maxLength,
  email,
  sameAs,
  helpers 
} from "vuelidate/lib/validators";
import { rfcRegex, isMinValidAge , isRFCValid } from "../constants/config";
import { scrollTo } from "../utils/index";
import Loader from "../components/Loader.vue"
import axios from "axios";
import _ from "lodash";

const hasSymbol = helpers.regex(
  "hasSymbol",
  /^.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./]+.*/
);
//Obtiene el regex del rfc de constants para validarlo
const rfc = helpers.regex("rfc",rfcRegex);
// import FormGenerator from '../components/FormsGenerator/FormGenerator'
const touchMap = new WeakMap();

export default {
  name: "ShoppingCarPage",
  data: function () {
    return {

      pageData: 0,

      siteKey: siKey,
      showLoader: false,
      showErrors: false,
      showAPIErrors: false,
      ApiErrorMessages: [],
      friendly_error:"",
      rfcValidates : false,


      //? Billing Data
      require_billing: "",
      personal_billing_expedition: "",
      personal_data_fiscal: {},
      external_data_fiscal: {},

      occupation: "",
      nationality: "",
      birth_country: "",
      fiscal_regime: "",
      cfdi_use: "",
      taxes_object:"",
      fiscal_situation_constancy:"",
      identity_document_front:"",
      identity_document_back:"",
      

      // Fiscal_Situation_Constancy
      fiscal_situation_constancy_file: '',
      fiscal_situation_constancy_dragging: false,
      fiscal_situation_constancy_fileError: '',
      fiscal_situation_constancy_validExtensions: ["pdf"],
      fiscal_situation_constancy_maximum_file_size: (200 * 1000), //KB

      // Identity_Document_Front
      identity_document_front_file: '',
      identity_document_front_dragging: false,
      identity_document_front_fileError: '',
      identity_document_front_validExtensions: ["pdf","jpg","png"],
      identity_document_front_maximum_file_size: (200 * 1000), //KB

      // Identity_Document_Back
      identity_document_back_file: '',
      identity_document_back_dragging: false,
      identity_document_back_fileError: '',
      identity_document_back_validExtensions: ["pdf","jpg","png"],
      identity_document_back_maximum_file_size: (200 * 1000), //KB

      truth_or_liar:"",

      countries: [],
      occupations:[],
      nationalities:[],
      list_regime_fiscal:[],
      list_cfdi_use:[],
      list_taxes_object:[],

      // General Arrays
      close_questions: [
        { id: 0, name: 'Sí', value: true },
        { id: 1, name: 'No', value: false },
      ],
    
      formTypeShowed: {
        id: 1,
        name: "Crear cuenta",
      },
      formTypeShowedOptions: [
        {
          id: 0,
          name: "Iniciar sesión",
        },
        {
          id: 1,
          name: "Crear cuenta",
        },
      ],
      formData: {
        /* Data for to create new user */
        site: 3,
        email: "",
        email_c: "",
        password: "",
        password_c: "",
        recaptchaToken: "",
        v:0,

        /* Extra user data*/
        name: "",
        first_name: "",
        last_name: "",
        gender: { id: null, name: "Selecciona un valor" },
        civil_status: { id: null, name: "Selecciona un valor" },
        rfc: "",
        homoclave: "",
        born_date: "",
        phone_number: "",

        /* Extra user data for address*/
        zip_code: { id: null, name: "Selecciona un valor" },
        country: { id: 1, name: "México" },
        state: { id: null, name: "Selecciona un valor" },
        town_council: { id: null, name: "Selecciona un valor" },
        colony: { id: null, name: "Selecciona un valor" },
        street: "",
        num_ext: "",
        num_int: "",
        address_id: null,





        // External user data billing
        billing_external_name: "",
        billing_external_first_name: "",
        billing_external_last_name: "",
        billing_external_gender: { id: null, name: "Selecciona un valor" },
        billing_external_civil_status: { id: null, name: "Selecciona un valor" },
        billing_external_rfc: "",
        billing_external_homoclave: "",
        billing_external_born_date: "",
        billing_external_phone_number: "",

        billing_external_zip_code: { id: null, name: "Selecciona un valor" },
        billing_external_country: { id: 1, name: "México" },
        billing_external_state: { id: null, name: "Selecciona un valor" },
        billing_external_town_council: { id: null, name: "Selecciona un valor" },
        billing_external_colony: { id: null, name: "Selecciona un valor" },
        billing_external_street: "",
        billing_external_num_ext: "",
        billing_external_num_int: "",
        billing_external_address_id: null,




        // Billing Data
        require_billing: {},
        personal_billing_expedition: {},
        personal_data_fiscal:{},
        external_data_fiscal: {},

        occupation: "",
        nationality: "",
        fiscal_regime: "",
        birth_country: "",
        cfdi_use: "",
        taxes_object: "",
        fiscal_situation_constancy:"",
        identity_document_back:"",
        identity_document_front:"",
        truth_or_liar:"",
      },


      formDataOptions: {
        gender: [],
        civil_status: [],
        zip_codes: [],
      },
      lastQuoteDataLocal: {
        requeriments: [
          {
            id: 29,
            value: "kiktro@gmail.com",
          },
          {
            id: 1,
            value: "Irvin",
          },
          {
            id: 2,
            value: new Date(),
          },
          {
            id: 4,
            value: "1",
          },
          {
            id: 5,
            value: "0",
          },
          {
            id: 6,
            value: "1",
          },
          {
            id: 7,
            value: {
              id: 207492,
              zip_code: "54473",
              name: "54473-CAMPESTRE LIBERACIÓN",
            },
          },
          {
            id: 8,
            value: "Fresnos 40",
          },
          {
            id: 30,
            value: "1",
          },
          {
            id: 31,
            value: "1000000",
          },
          {
            id: 32,
            value: "200000",
          },
          {
            id: 22,
            value: "30",
          },
        ],
        categories: [8],
      },

      help_fiscal_situation_constancy:false
    };
  },
  components: {
    "loader": Loader,
    'vue-recaptcha': VueRecaptcha
  },
  validations() {
    let validations = {
      formData: {

        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        first_name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        last_name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        gender: {
          id: {
            required,
          },
        },
        civil_status: {
          id: {
            required,
          },
        },
        rfc: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          rfc,
          isRFCValid:(value) => {

            var rfc = value.toUpperCase()
            var name = this.formData.name.toUpperCase()
            var first_name = this.formData.first_name.toUpperCase()
            var last_name = this.formData.last_name.toUpperCase()
            var born_date = this.formData.born_date

            if(rfc.length == 10){
              var rfc_first = first_name.substring(0,1)
              var rfc_second = () => {
                  var vowels = ["A" , "E" , "I" , "O" , "U"]
                  var rfc_second = ""
                  var rfc_second_found = false
                  for (let i = 0; i < first_name.length; i++) {
                      var char = first_name.charAt(i)
                      if(!rfc_second && vowels.indexOf(char)!= -1){
                          // First Vowel Found
                          rfc_second = char
                          rfc_second_found = true
                      }
                  }
                  return rfc_second
              }
              var rfc_third = last_name.substring(0,1)
              var rfc_fourth = name.substring(0,1)

              var rfc_year = born_date.substring(2,4)
              var rfc_month = born_date.substring(5,7)
              var rfc_day = born_date.substring(8,10)

              var rfc_build = `${rfc_first}${rfc_second()}${rfc_third}${rfc_fourth}${rfc_year}${rfc_month}${rfc_day}`

              if(rfc_build == rfc){
                  return true
              } else {
                  return false
              }

            } else {
              return false
            }

          }
        },
        homoclave: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(3),
        },
        born_date: {
          required,
          isMinValidAge,
          maxValue: (value) => value < new Date().toISOString(),
        },
        phone_number: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(20),
        },
        street: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100),
        },
        num_ext: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(5)
        },
        num_int: {},






        //? Billing Data
        require_billing: {},
        personal_billing_expedition : {
          required: requiredIf(function () {
            return this.formData.require_billing.value
          })
        },




        //TODO External User Billing Data (Unicamente cuando se requiere facturar a nombre externo)
        billing_external_name: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        billing_external_first_name: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        billing_external_last_name: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        billing_external_gender: {
          id: {
            required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
            }),
          },
        },
        billing_external_civil_status: {
          id: {
            required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
            }),
          },
        },
        billing_external_rfc: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(10),
          maxLength: maxLength(10),
          rfc,
        },
        billing_external_homoclave: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(3),
          maxLength: maxLength(3),
        },
        billing_external_born_date: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          isMinValidAge,
          maxValue: (value) => value < new Date().toISOString(),
        },
        billing_external_phone_number: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(10),
          maxLength: maxLength(20),
        },

        billing_external_street: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(1),
          maxLength: maxLength(100),
        },
        billing_external_num_ext: {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && !this.formData.personal_billing_expedition.value)
          }),
          minLength: minLength(1),
          maxLength: maxLength(5)
        },
        billing_external_num_int: {},





        // BIlling Data
        occupation : {
          required: requiredIf(function () {
            return this.formData.require_billing.value
          })
        },
        nationality : {
          required: requiredIf(function () {
            return this.formData.require_billing.value
          })
        },
        birth_country : {
          required: requiredIf(function () {
            return this.formData.require_billing.value
          })
        },
        fiscal_regime : {
          required: requiredIf(function () {
            return this.formData.require_billing.value
          })
        },
        cfdi_use : {
          required: requiredIf(function () {
            return this.formData.require_billing.value
          })
        },
        taxes_object : {

        },

        fiscal_situation_constancy : {
          required: requiredIf(function () {
            return (this.formData.require_billing.value)
          })
        },
        identity_document_back : {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && this.selectedProductCategories[0].id != 5)
          })
        },
        identity_document_front : {
          required: requiredIf(function () {
            return (this.formData.require_billing.value && this.selectedProductCategories[0].id != 5)
          })
        },
        // truth_or_liar : {
        //   required: requiredIf(function () {
        //     return (this.formData.require_billing.value)
        //   })
        // },
      },
    };
    if (this.formTypeShowed.id == 1 && !this.loggedIn) {
      /* Account create */
      validations.formData["email"] = {
        required,
        email,
      };
      validations.formData["email_c"] = {
        required,
        sameAsEmail: sameAs("email"),
      };
      validations.formData["password"] = {
        required,
        hasSymbol,
        minLength: minLength(5),
      };
      validations.formData["password_c"] = {
        required,
        sameAsPassword: sameAs("password"),
      };
    }

    return validations;
  },
  props: [],
  computed: {
    ...mapGetters([
      "lastQuoteData",
      "user",
      "userData",
      "loggedIn",
      "shoppingCar",
    ]),


    selectedProductCategories() {
      return this.$store.state.BuyProcessStore.productCategories.userSelection;
    },



    

  },
  mounted() {
    let self = this;
    this.lastQuoteDataLocal = this.lastQuoteData;
    self.setInitialState();
    setTimeout(() => {
      self.$v.$reset();
    }, 5000);


    var fiscal_catalog = (this.selectedProductCategories[0].id != 5)?this.$APIServices.catalog_fiscal:this.$APIServices.catalog_fiscal_dentegra

    // Fiscal Catalogs
    this.$http
    .get(this.$APIEndpoint + fiscal_catalog)
    .then((response) => {

      var catalogs = response.data
      catalogs.forEach(catalog => {

        var catalog_name = catalog["name"]
        var catalog_json = catalog["json"]

        switch(catalog_name){
          
          case "Ocupaciones":
            catalog_json.forEach(catalog_json_object => {
              // var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.occupations.push(value)
            });
          break;
          case "Nacionalidades":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.nationalities.push(`(${value}) ${name}`)           
            });
          break;
          case "Países":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.countries.push(`(${value}) ${name}`)
            });
          break;
          case "Regimenes_Fiscales":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.list_regime_fiscal.push(`(${value}) ${name}`)
            });
          break;
          case "Uso_CFDI":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.list_cfdi_use.push(`(${value}) ${name}`)
            });
          break;
          case "Objeto_De_Impuesto":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.list_taxes_object.push(`(${value}) ${name}`)
            });
          break;
        }

      });

    });


    //? Esta forma se va a utilizar para cargar los estados previos del usuario de la base de datos.
    // this.formData.personal_data_fiscal = "Generalitos"
    // this.formData.require_billing = "Cuencas"







    // Filling Previous Values filled in the formulary

    // Sura
    if(this.selectedProductCategories[0].id == 7){
      var { value } = this.lastQuoteData.requeriments.filter(element => element.id == 38)[0]
      var { name } = value
      if(name=="Hombre"){
        this.$v.formData.gender.$model = { "id": 1, "name": "Masculino" }
      } else if(name=="Mujer") {
        this.$v.formData.gender.$model = { "id": 2, "name": "Femenino" }    
      }
    }










    // Fill Data Only with Registered Users
    if(this.userData) {
      
      // this.formData.require_billing.id = 0 
      //  = (this.userData.require_billing) ? "Sí" : "No"  
      this.formData.require_billing = (this.userData.require_billing) ? { "id": 0, "name": "Sí", "value": true } : { "id": 1, "name": "No", "value": false }
      this.formData.personal_billing_expedition = (this.userData.personal_billing_expedition) ? { "id": 0, "name": "Sí", "value": true } : { "id": 1, "name": "No", "value": false }
    
      this.formData.personal_data_fiscal = this.userData.personal_data_fiscal
      this.formData.external_data_fiscal = this.userData.external_data_fiscal

      var personal_billing_expedition = this.formData.personal_billing_expedition.value
      var personal_data_fiscal = this.formData.personal_data_fiscal
      var external_data_fiscal = this.formData.external_data_fiscal


      // Billing External Data Filing
      this.formData.billing_external_name = external_data_fiscal["general"]["name"]
      this.formData.billing_external_first_name = external_data_fiscal["general"]["first_name"]
      this.formData.billing_external_last_name = external_data_fiscal["general"]["last_name"]
      this.formData.billing_external_gender = external_data_fiscal["general"]["gender"]
      this.formData.billing_external_civil_status = external_data_fiscal["general"]["civil_status"]
      this.formData.billing_external_rfc = external_data_fiscal["fiscal_data"]["rfc"]
      this.formData.billing_external_homoclave = external_data_fiscal["fiscal_data"]["homoclave"]
      this.formData.billing_external_born_date = external_data_fiscal["general"]["born_date"]
      this.formData.billing_external_phone_number = external_data_fiscal["general"]["phone_number"]

      this.formData.billing_external_zip_code = external_data_fiscal["address"]["zip_code"]
      this.formData.billing_external_country = external_data_fiscal["address"]["country"]
      this.formData.billing_external_state = external_data_fiscal["address"]["state"]
      this.formData.billing_external_town_council = external_data_fiscal["address"]["town_council"]
      this.formData.billing_external_colony = external_data_fiscal["address"]["colony"]
      this.formData.billing_external_street = external_data_fiscal["address"]["street"]
      this.formData.billing_external_num_ext = external_data_fiscal["address"]["num_ext"]
      this.formData.billing_external_num_int = external_data_fiscal["address"]["num_int"]


      //TODO Billing Data
      if(personal_billing_expedition){
        this.formData.occupation = personal_data_fiscal["fiscal_data"]["ocupation"]
        this.formData.nationality = personal_data_fiscal["fiscal_data"]["nationality"]
        this.formData.birth_country = personal_data_fiscal["fiscal_data"]["birth_country"]
        this.formData.fiscal_regime = personal_data_fiscal["fiscal_data"]["fiscal_regime"]
        this.formData.cfdi_use = personal_data_fiscal["fiscal_data"]["cfdi_use"]
        this.formData.taxes_object = personal_data_fiscal["fiscal_data"]["taxes_object"]
        this.formData.fiscal_situation_constancy = personal_data_fiscal["fiscal_data"]["fiscal_situation_constancy"]
        this.formData.identity_document_back = personal_data_fiscal["fiscal_data"]["identity_document_back"]
        this.formData.identity_document_front = personal_data_fiscal["fiscal_data"]["identity_document_front"]

      } else {
        this.formData.occupation = external_data_fiscal["fiscal_data"]["ocupation"]
        this.formData.nationality = external_data_fiscal["fiscal_data"]["nationality"]
        this.formData.birth_country = external_data_fiscal["fiscal_data"]["birth_country"]
        this.formData.fiscal_regime = external_data_fiscal["fiscal_data"]["fiscal_regime"]
        this.formData.cfdi_use = external_data_fiscal["fiscal_data"]["cfdi_use"]
        this.formData.taxes_object = external_data_fiscal["fiscal_data"]["taxes_object"]     
        this.formData.fiscal_situation_constancy = external_data_fiscal["fiscal_data"]["fiscal_situation_constancy"]
        this.formData.identity_document_back = external_data_fiscal["fiscal_data"]["identity_document_back"]
        this.formData.identity_document_front = external_data_fiscal["fiscal_data"]["identity_document_front"] 
     }

    }

  },
  methods: {
    ...mapActions([
      "getZipCode",
      "signIn",
      "getToken",
      "getUserDetail",
      "getGender",
      "getCivilStatus",
      "updateOrCreateUser",
      "updateOrCreateUserAddress",
    ]),

    ...mapMutations(["setIsUserSideBarOpen"]), 


    scrollToFirstError() {
      setTimeout(() => {
        let duration = 300;
        let parentEl = document.getElementById("scroll-wraper-content");
        let targetEl = document.querySelector(".t-form-error:first-of-type");

        scrollTo(duration, parentEl, targetEl);
      }, 100);
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    async setInitialState() {
      let self = this;
      self.showLoader = true;


      let zip_code = this.getZipCodeOrNone(this.lastQuoteData.requeriments);
      if (zip_code) {
        let payload = {
          params: {
            full_data: true,
          },
          id: zip_code.value.id,
        };
        await this.getZipCode(payload).then((response) => {
          self.fillDefaultDataForm(response);
        });
      } else {
        self.fillDefaultDataForm({});
      }


      let billing_external_zip_code = this.getZipCodeOrNone(this.lastQuoteData.requeriments);
      if (billing_external_zip_code) {
        let payload = {
          params: {
            full_data: true,
          },
          id: billing_external_zip_code.value.id,
        };
        await this.getZipCode(payload).then((response) => {
          self.fillDefaultDataForm(response);
        });
      } else {
        self.fillDefaultDataForm({});
      }



      this.getGender().then((response) => {
        self.formDataOptions.gender = [
          { id: null, name: "Selecciona un valor" },
          ...response,
        ];
      });
      this.getCivilStatus().then((response) => {
        self.formDataOptions.civil_status = [
          { id: null, name: "Selecciona un valor" },
          ...response,
        ];
      });

      if (self.loggedIn) {
        self.getUserDetail().then(() => {
          self.showLoader = false;
        });
      } else {
        self.showLoader = false;
      }
    },
    getZipCodeOrNone(data) {
      let reqFinded = data.filter((item) => item.id == 7);
      if (reqFinded.length) {
        return reqFinded[0];
      } else {
        return null;
      }
    },
    calculateUserData(newUserData) {

      this.formData.email = newUserData.email;
      this.formData.name = newUserData.name
        ? newUserData.name
        : this.formData.name;

      this.formData.first_name = newUserData.first_name
        ? newUserData.first_name
        : this.formData.first_name;
      this.formData.last_name = newUserData.last_name
        ? newUserData.last_name
        : this.formData.last_name;
      this.formData.rfc = newUserData.rfc ? newUserData.rfc : this.formData.rfc;
      this.formData.homoclave = newUserData.homoclave
        ? newUserData.homoclave
        : this.formData.homoclave;
      this.formData.born_date = this.formData.born_date
        ? this.formData.born_date
        : newUserData.born_date;
      this.formData.phone_number = this.formData.phone_number
        ? this.formData.phone_number
        : newUserData.phone_number;
      this.formData.gender = newUserData.gender
        ? newUserData.gender
        : this.formData.gender;
      this.formData.civil_status = newUserData.civil_status
        ? newUserData.civil_status
        : this.formData.civil_status;

      if (newUserData.user_address.length) {
        let address = newUserData.user_address[0];
        this.formData.country = address.country;
        this.formData.zip_code = {
          ...address.zip_code,
          name: address.zip_code.zip_code,
        };
        this.formData.state = address.state;
        this.formData.town_council = address.town_council;
        this.formData.colony = address.colony;
        this.formData.street = this.formData.street
          ? this.formData.street
          : address.street;

        this.formData.num_int = this.formData.num_int
          ? this.formData.num_int
          : address.num_int;
        this.formData.num_ext = this.formData.num_ext
          ? this.formData.num_ext
          : address.num_ext;

        this.formData.address_id = address.id;





        //Billing Data

        this.formData.require_billing = this.formData.require_billing
          ? this.formData.require_billing
          : newUserData.require_billing;

        this.formData.personal_billing_expedition = this.formData.personal_billing_expedition
          ? this.formData.personal_billing_expedition
          : newUserData.personal_billing_expedition;

        this.formData.personal_data_fiscal = this.formData.personal_data_fiscal
          ? this.formData.personal_data_fiscal
          : newUserData.personal_data_fiscal;

        this.formData.external_data_fiscal = this.formData.external_data_fiscal
          ? this.formData.external_data_fiscal
          : newUserData.external_data_fiscal;

        this.formData.personal_billing_expedition = this.formData.personal_billing_expedition
          ? this.formData.personal_billing_expedition
          : newUserData.personal_billing_expedition;







        this.formData.fiscal_regime = this.formData.fiscal_regime
          ? this.formData.fiscal_regime
          : newUserData.fiscal_regime;

        this.formData.birth_country = this.formData.birth_country
          ? this.formData.birth_country
          : newUserData.birth_country;

        this.formData.occupation = this.formData.occupation
          ? this.formData.occupation
          : newUserData.occupation;

        this.formData.nationality = this.formData.nationality
          ? this.formData.nationality
          : newUserData.nationality;

        this.formData.cfdi_use = this.formData.cfdi_use
          ? this.formData.cfdi_use
          : newUserData.cfdi_use;

        this.formData.taxes_object = this.formData.taxes_object
          ? this.formData.taxes_object
          : newUserData.taxes_object;


        this.formData.fiscal_situation_constancy = this.formData.fiscal_situation_constancy
          ? this.formData.fiscal_situation_constancy
          : newUserData.fiscal_situation_constancy;


        this.formData.identity_document_back = this.formData.identity_document_back
          ? this.formData.identity_document_back
          : newUserData.identity_document_back;


        this.formData.identity_document_front = this.formData.identity_document_front
          ? this.formData.identity_document_front
          : newUserData.identity_document_front;

      }
    },
    fillDefaultDataForm(locationData) {
      // Knowed data
      try {
        let dateString = this.formatDate(
          this.lastQuoteDataLocal.requeriments.filter((item) => item.id == 2)[0]
            .value
        );
        this.formData.born_date = dateString;
      } catch (error) {
        this.formData.born_date = this.formatDate(String(new Date()));
      }

      try {
        let fullName = this.lastQuoteDataLocal.requeriments.filter(
          (item) => item.id == 1
        )[0].value;
        this.formData.name = fullName[0];
        this.formData.first_name = fullName[1];
        this.formData.last_name = fullName[2];
      } catch (error) {
        this.formData.name = "";
      }
      this.formData.email = this.lastQuoteDataLocal.requeriments.filter(
        (item) => item.id == 29
      )[0].value;


      this.formData.country = locationData.country;
      this.formData.zip_code = {
        ...locationData.zip_code,
        name: locationData.zip_code ? locationData.zip_code.name : "",
      };

      this.formData.state = locationData.state;
      this.formData.town_council = locationData.town_council;
      this.formData.colony = locationData.colony;

      try {
        let auxStreet = this.lastQuoteDataLocal.requeriments.filter(
          (item) => item.id == 8
        )[0].value;
        this.formData.street = auxStreet[0];
        this.formData.num_ext = auxStreet[1];
        this.formData.num_int = "";
      } catch (error) {
        this.formData.street = "";
        this.formData.num_ext = "";
        this.formData.num_int = "";
      }

      this.BillingDataChange()      
    },

    BillingExternalFillDefaultDataForm(locationData) {

      this.formData.billing_external_country = locationData.country;
      this.formData.billing_external_zip_code = {
        ...locationData.zip_code,
        name: locationData.zip_code ? locationData.zip_code.name : "",
      };

      this.formData.billing_external_state = locationData.state;
      this.formData.billing_external_town_council = locationData.town_council;
      this.formData.billing_external_colony = locationData.colony;
      
      this.BillingDataChange()

    },
            
        setZipCodeOptions(data) {
          this.formDataOptions.zip_codes = data;
        },
        formatDate(date) {
          if (typeof date == "string") {
            try {
              date = new Date(date);
            } catch (error) {
              date = new Date();
            }
          }
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          day = ("0" + day).slice(-2);
          month = ("0" + month).slice(-2);
          return `${year}-${month}-${day}`;
        },
        onSearchCP(search, loading) {
          if (search.length) {
            loading(true);
            this.search(
              loading,
              search,
              this,
              this.api_resource,
              this.httpConnector
            );
          }
        },
        search: _.debounce((loading, search, vm, api_resource, httpConnector) => {
          let url =
            vm.$APIEndpoint + vm.$APIServices.zip_code + "?value=" + String(search);
          let http = httpConnector ? httpConnector : axios;
          http.get(url).then((res) => {
            vm.setZipCodeOptions(res.data);
            loading(false);
          });
        }, 350),
        async refreshAddressInfo() {
          
          let self = this;
          let payload = {
            params: {
              full_data: true,
            },
            id: self.formData.zip_code.id,
          };

          console.log("self.formData.zip_code.id:")
          console.log(self.formData.zip_code.id)+          

          await self.getZipCode(payload).then((response) => {
            self.fillDefaultDataForm(response);
          });
        },

        async BillingExternalRefreshAddressInfo() {

          let self = this;          
          let payload = {
            params: {
              full_data: true,
            },
            id: self.formData.billing_external_zip_code.id,
          };
          
          await self.getZipCode(payload).then((response) => {
            self.BillingExternalFillDefaultDataForm(response);
          });
        },    



        goToBillingData(){
          let routeData = this.$router.resolve({name: 'BillingData'});
          window.open(routeData.href, '_blank');
        },
        async onSubmit() {
          this.$refs.recaptcha.execute();
        },
        async onVerify(recaptchaToken){

          let self = this;
          self.$v.$touch();
          this.showLoader = true

          self.formData["recaptchaToken"] = recaptchaToken
          this.$refs.recaptcha.reset();

          if (self.$v.$invalid) {
            self.showErrors = true;
            self.showLoader = false;
            self.scrollToFirstError();
          } else {
            self.showErrors = false;

            if (!self.loggedIn) {

              let url = this.$APIEndpoint + this.$APIServices.get_info_users

              await this.$http.get(url)
              .then((response) => {

                var response_data = response.data
                var rfcs_array = []
                var email_array = []

                for (let i = 0; i < response_data.length; i++) {
                  rfcs_array.push(response_data[i]['rfc'])
                }

                for (let i = 0; i < response_data.length; i++) {
                  email_array.push(response_data[i]['email'])
                }
                
                if( rfcs_array.indexOf(self.formData.rfc.toString()) == -1 ){

                  if( email_array.indexOf(self.formData.email.toString()) == -1 ){
                    this.formValidate()
                    this.showLoader = false
                  } else {
                    this.showLoader = false
                    self.friendly_error = "El Email con el que tratas de registrarte ya existe en la Base de Datos."
                    self.showLoader = false;

                    self.$swal({
                      icon: "info",
                      title: "Oops!",
                      text: "El Email con el que tratas de registrarte ya existe en la Base de Datos cambielo por uno diferente o por el contrario trate de loguearse con su cuenta",
                      showConfirmButton: false,
                      timer:4000
                    })

                  }

                } else {
                  this.showLoader = false
                  self.friendly_error = "El RFC ya existe en la Base de Datos."
                  self.showLoader = false;

                  self.$swal({
                    icon: "info",
                    title: "Oops!",
                    text: "El RFC ingresado ya existe en la Base de Datos cambielo por uno diferente o por el contrario trate de loguearse con su cuenta",
                    showConfirmButton: false,
                    timer:4000
                  })

                }                

              })
              .catch((error)=> {
                console.log("MX2 Error:")
                console.log(error)
                this.showLoader = false
                throw error;
              }).finally(()=> { this.showLoader = false} )

            } else {

              // console.log("Enter Submit")
              let url = this.$APIEndpoint + this.$APIServices.get_info_users
              // console.log(path)

              await this.$http.get(url)
              .then((response) => {

                  var response_data = response.data
                  var rfcs_array = []

                  for (let i = 0; i < response_data.length; i++) {
                    rfcs_array.push(response_data[i]['rfc'])
                  }
                  var idUserFound = this.userData.id

                  if(rfcs_array.indexOf(self.formData.rfc.toString()) != -1){
                    idUserFound = response_data[rfcs_array.indexOf(self.formData.rfc.toString())].id
                    // console.log(idUserFound)
                    // console.log(rfcs_array.indexOf(self.formData.rfc.toString()))
                  }

                  if( rfcs_array.indexOf(self.formData.rfc.toString()) == -1 || idUserFound == this.userData.id){
                    this.formValidate()
                  } else {
                    this.showLoader = false
                    self.friendly_error = "El RFC ya existe en la Base de Datos."
                    self.showLoader = false;
                    // self.$notify({
                    //   group: "foo",
                    //   title: "Error en registro",
                    //   text: self.friendly_error,
                    //   type: "error",
                    //   duration: 10000,
                    // });

                    alert("El RFC ya existe en la Base de Datos")

                  }
              })
              .catch((error)=> {
                  // console.log(error)
                  this.showLoader = false
                  throw error
              }).finally(()=> { this.showLoader = false} )

              // this.showLoader = false
              }

          }

        },
        onExpired: function () {
          this.$refs.recaptcha.reset();
        },
        async formValidate() {
          let self = this;
          self.$v.$touch();

          if (self.$v.$invalid) {
            self.showErrors = true;
            self.showLoader = false;
            self.scrollToFirstError();
          } else {
            self.showErrors = false;
            if (this.formTypeShowed.id == 1) {
              if (self.formData.email != self.formData.email) {
                return false;
              }
            }

            let userPayload = {
              id: self.user ? self.user.id : null,
              data: {
                email: self.formData.email,
                name: self.formData.name,
                first_name: self.formData.first_name,
                last_name: self.formData.last_name,
                rfc: self.formData.rfc,
                homoclave: self.formData.homoclave,
                born_date: self.formData.born_date,
                gender: self.formData.gender.id,
                civil_status: self.formData.civil_status.id,
                phone_number: self.formData.phone_number,
                recaptchaToken: self.formData.recaptchaToken,
                v: 0,



                // Billing Data
                //? Estos campos son los que se mandan al ORM para interactuar con las columnas de la base de datos.

                require_billing: self.formData.require_billing.value,
                personal_billing_expedition: self.formData.personal_billing_expedition.value,
                
                personal_data_fiscal: self.formData.personal_data_fiscal,
                external_data_fiscal: self.formData.external_data_fiscal,
              },
            };

            let userCreatePayload = {
              id: null,
              data: {
                site: self.formData.site,
                email: self.formData.email,
                email_c: self.formData.email,
                password: self.formData.password,
                password_c: self.formData.password_c,
                recaptchaToken: self.formData.recaptchaToken,
                v: 0
              },
            };

            let userAddressPayload = JSON.parse(
              JSON.stringify({
                id: self.formData.address_id ? self.formData.address_id : null,
                data: {
                  country: self.formData.country ? self.formData.country.id : null,
                  zip_code: self.formData.zip_code ? self.formData.zip_code.id : null,
                  state: self.formData.state ? self.formData.state.id : null,
                  town_council: self.formData.town_council ? self.formData.town_council.id : null,
                  colony: self.formData.colony ? self.formData.colony.id : null,
                  street: self.formData.street,
                  num_ext: self.formData.num_ext,
                  num_int: self.formData.num_int,
                },
              })
            );
      

            if (!self.loggedIn) {
              //Indicador para validación recaptcha
              userCreatePayload.data["v"] = 1
              await self
                .signIn(userCreatePayload)
                .catch((error) => {
                  // console.log(error)
                  self.showLoader = false;
                  self.$notify({
                    group: "foo",
                    title: "Error en registro",
                    text: error,
                    type: "error",
                    duration: 10000,
                  });
                });
              
              //Indicador para validación recaptcha
              userCreatePayload.data["v"] = 2
              await self
                .getToken(userCreatePayload.data)
                .then(() => {
                  userPayload.id = self.user.id;
                })
                .catch((e) => {
                  self.showLoader = false;
                  self.$notify({
                    group: "foo",
                    title: "Error en registro",
                    text: e.response.data.error,
                    duration: 10000,
                  });
                });

                //Indicador de validación recaptcha
                userPayload.data["v"] = 2
            }
            else {
              //Indicador de validación recaptcha
              userPayload.data["v"] = 1
            }

            let errors = [];
            //Crea/actualiza usuario
            await self
              .updateOrCreateUser(userPayload)
              .catch((error) => {
                self.showLoader = false;
                errors.push(error.response.data);
              });

            //Crea/actualiza dirección del usuario
            await self
              .updateOrCreateUserAddress(userAddressPayload)
              .then(() => self.getUserDetail()
              )
              .catch((error) => {
                self.showLoader = false;
                errors.push(error.response.data);
              });

            //Si existe algún error, despliega notificación en pantalla
            if (errors.length) {
              errors.forEach((error) => {
                self.$notify({
                  group: "foo",
                  title: error.title,
                  text: `<b>${error.code}</b>: ${error.detail}`,
                  duration: 3000,
                });

              });
            } else {
              /* Si el usuario ya está firmado, le asignamos la cotización */
              self.shoppingCar.makeOwn();
              self.$router.push({ name: "shopping-car", params: {} });
            }
          }
          // console.log('EndOfFunctions')
          self.showLoader = false;
          return false;
          
        },

        BillingDataChange(){

          // La factura es personal o Externa?
          var personal_billing_expedition = this.formData.personal_billing_expedition.value

          //? Si la factura será emitida a su nombre del cliente
          if(personal_billing_expedition){

            this.formData.personal_data_fiscal = {
              "general":{
                "email" : this.formData.email,
                "name" : this.formData.name,
                "first_name" : this.formData.first_name,
                "last_name" : this.formData.last_name,
                "gender" : this.formData.gender,
                "civil_status" : this.formData.civil_status,
                "born_date" : this.formData.born_date,
                "phone_number" : this.formData.phone_number,
              },
              "address":{
                "zip_code":this.formData.zip_code,
                "country":this.formData.country,
                "state":this.formData.state,
                "town_council":this.formData.town_council,
                "colony":this.formData.colony,
                "street":this.formData.street,
                "num_ext":this.formData.num_ext,
                "num_int":this.formData.num_int,
              },
              "fiscal_data":{
                "rfc" : this.formData.rfc,
                "homoclave" : this.formData.homoclave,

                "ocupation" :  this.formData.occupation,
                "nationality":   this.formData.nationality,
                "birth_country": this.formData.birth_country,
                "fiscal_regime": this.formData.fiscal_regime,
                "cfdi_use": this.formData.cfdi_use,
                "taxes_object": this.formData.taxes_object,
                "fiscal_situation_constancy":this.formData.fiscal_situation_constancy,
                "identity_document_front":this.formData.identity_document_front,
                "identity_document_back":this.formData.identity_document_back,
              }
            }

          }
          //? Si la factura será emitida a nombre de un externo
          else {

            this.formData.external_data_fiscal = {
              "general":{
                "email" : this.formData.email,
                "name" : this.formData.billing_external_name,
                "first_name" : this.formData.billing_external_first_name,
                "last_name" : this.formData.billing_external_last_name,
                "gender" : this.formData.billing_external_gender,
                "civil_status" : this.formData.billing_external_civil_status,
                "born_date" : this.formData.billing_external_born_date,
                "phone_number" : this.formData.billing_external_phone_number,
              },
              "address":{
                "zip_code":this.formData.billing_external_zip_code,
                "country":this.formData.billing_external_country,
                "state":this.formData.billing_external_state,
                "town_council":this.formData.billing_external_town_council,
                "colony":this.formData.billing_external_colony,
                "street":this.formData.billing_external_street,
                "num_ext":this.formData.billing_external_num_ext,
                "num_int":this.formData.billing_external_num_int,
              },
              "fiscal_data" : {
                "rfc" : this.formData.billing_external_rfc,
                "homoclave" : this.formData.billing_external_homoclave,
                "ocupation" : this.formData.occupation,
                "nationality" : this.formData.nationality,
                "birth_country" : this.formData.birth_country,
                "fiscal_regime" : this.formData.fiscal_regime,
                "cfdi_use" : this.formData.cfdi_use,
                "taxes_object" : this.formData.taxes_object,
                "fiscal_situation_constancy":this.formData.fiscal_situation_constancy,
                "identity_document_front":this.formData.identity_document_front,
                "identity_document_back":this.formData.identity_document_back,
              }
            }
            
          }

        },

        FullFillBillingChange(){

          if(this.userData){

            // La factura es personal o Externa?
            var personal_billing_expedition = this.formData.personal_billing_expedition.value
            var personal_data_fiscal = this.userData.personal_data_fiscal
            var external_data_fiscal = this.userData.external_data_fiscal
            

            //? Si la factura será emitida a su nombre del cliente
            if(personal_billing_expedition){
              this.formData.occupation = personal_data_fiscal["fiscal_data"]["ocupation"]
              this.formData.nationality = personal_data_fiscal["fiscal_data"]["nationality"]
              this.formData.birth_country = personal_data_fiscal["fiscal_data"]["birth_country"]
              this.formData.fiscal_regime = personal_data_fiscal["fiscal_data"]["fiscal_regime"]
              this.formData.cfdi_use = personal_data_fiscal["fiscal_data"]["cfdi_use"]
              this.formData.taxes_object = personal_data_fiscal["fiscal_data"]["taxes_object"]

              this.formData.identity_document_front = personal_data_fiscal["fiscal_data"]["identity_document_front"]
              this.formData.identity_document_back = personal_data_fiscal["fiscal_data"]["identity_document_back"]
              this.formData.fiscal_situation_constancy = personal_data_fiscal["fiscal_data"]["fiscal_situation_constancy"]
            }

            //? Si la factura será emitida a nombre de un externo
            else {
              this.formData.occupation = external_data_fiscal["fiscal_data"]["ocupation"]
              this.formData.nationality = external_data_fiscal["fiscal_data"]["nationality"]
              this.formData.birth_country = external_data_fiscal["fiscal_data"]["birth_country"]
              this.formData.fiscal_regime = external_data_fiscal["fiscal_data"]["fiscal_regime"]
              this.formData.cfdi_use = external_data_fiscal["fiscal_data"]["cfdi_use"]
              this.formData.taxes_object = external_data_fiscal["fiscal_data"]["taxes_object"]

              this.formData.identity_document_front = external_data_fiscal["fiscal_data"]["identity_document_front"]
              this.formData.identity_document_back = external_data_fiscal["fiscal_data"]["identity_document_back"]
              this.formData.fiscal_situation_constancy = external_data_fiscal["fiscal_data"]["fiscal_situation_constancy"]
            }

          }

        },







        // Better way to do?
        onChangeFileFiscalSituationConstancy(e) {

          var files = e.target.files || e.dataTransfer.files;
          
          if (!files.length) {
              this.fiscal_situation_constancy_dragging = false;
              return;
          }
          
          var file = files[0]

          // Evaluating Valid File            
          var extension = (file) ? file.name.split('.').pop() : ''
         

          if (this.fiscal_situation_constancy_validExtensions.indexOf(extension) < 0) {
            this.showErrors = true;
            this.fiscal_situation_constancy_fileError = `Porfavor selecciona un archivo con las extensiones soportadas (${this.fiscal_situation_constancy_validExtensions})`
            this.fiscal_situation_constancy_dragging = false;
            return;
          } else if(file.size > this.fiscal_situation_constancy_maximum_file_size){
            this.showErrors = true;
            this.fiscal_situation_constancy_fileError = `Porfavor selecciona un archivo que no sobrepase los ${this.fiscal_situation_constancy_maximum_file_size / 1000}KB`
            this.fiscal_situation_constancy_dragging = false;
            return;
          }

          this.showErrors = false;
          this.fiscal_situation_constancy_file = file;

          let self = this;

          // Input File Content to B64
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            var b64_file = reader.result
            self.formData.fiscal_situation_constancy = b64_file
            self.BillingDataChange()
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };

          this.fiscal_situation_constancy_dragging = false;
        
        },

        onChangeFileIdentityDocumentFront(e) {

          var files = e.target.files || e.dataTransfer.files;
          
          if (!files.length) {
              this.identity_document_front_dragging = false;
              return;
          }
          
          var file = files[0]

          // Evaluating Valid File            
          var extension = (file) ? file.name.split('.').pop() : ''

          if (this.identity_document_front_validExtensions.indexOf(extension) < 0) {
            this.showErrors = true;
            this.identity_document_front_fileError = `Porfavor selecciona un archivo con las extensiones soportadas (${this.identity_document_front_validExtensions})`
            this.identity_document_front_dragging = false;
            return;
          } else if(file.size > this.identity_document_front_maximum_file_size){
            this.showErrors = true;
            this.identity_document_front_fileError = `Porfavor selecciona un archivo que no sobrepase los ${this.identity_document_front_maximum_file_size / 1000}KB`
            this.identity_document_front_dragging = false;
            return;
          }

          this.showErrors = false;
          this.identity_document_front_file = file;

          let self = this;

          // Input File Content to B64
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            var b64_file = reader.result
            self.formData.identity_document_front = b64_file
            self.BillingDataChange()
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };

          this.identity_document_front_dragging = false;
        },


        onChangeFileIdentityDocumentBack(e) {

          var files = e.target.files || e.dataTransfer.files;
          
          if (!files.length) {
              this.identity_document_back_dragging = false;
              return;
          }
          
          var file = files[0]

          // Evaluating Valid File            
          var extension = (file) ? file.name.split('.').pop() : ''

          if (this.identity_document_back_validExtensions.indexOf(extension) < 0) {
            this.showErrors = true;
            this.identity_document_back_fileError = `Porfavor selecciona un archivo con las extensiones soportadas (${this.identity_document_back_validExtensions})`
            this.identity_document_back_dragging = false;
            return;
          } else if(file.size > this.identity_document_back_maximum_file_size){
            this.showErrors = true;
            this.identity_document_back_fileError = `Porfavor selecciona un archivo que no sobrepase los ${this.identity_document_back_maximum_file_size / 1000}KB`
            this.identity_document_back_dragging = false;
            return;
          }

          this.showErrors = false;
          this.identity_document_back_file = file;

          let self = this;

          // Input File Content to B64
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            var b64_file = reader.result
            self.formData.identity_document_back = b64_file
            self.BillingDataChange()
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };

          this.identity_document_back_dragging = false;
        },

        removeFileFiscalSituationConstancy() {
          this.fiscal_situation_constancy_file = '';
          this.formData.fiscal_situation_constancy = '';
          this.BillingDataChange();
        },

        removeFileIdentityDocumentFront() {
          this.identity_document_front_file = '';
          this.formData.identity_document_front = '';
          this.BillingDataChange();
        },
        removeFileIdentityDocumentBack() {
          this.identity_document_back_file = '';
          this.formData.identity_document_back = '';
          this.BillingDataChange();          
        },
        

      },
      watch: {
        userData: function (newVal) {
          this.calculateUserData(newVal);
        },    
        // pageData: function(value) {
        //     // If "pageData" ever changes, then we will console log its new value.
        //     console.log(value);
        // }
      },
    };

</script>

<style >
  [disabled] {
    opacity: 0.7;
  }



</style>